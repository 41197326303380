import React, {useEffect} from 'react';
import axios from "axios";
import locale from "antd/es/date-picker/locale/ru_RU";
import {
    Form,
    Input,
    Radio,
    DatePicker,
    InputNumber,
    Switch, Button, FormProps,
} from 'antd';
import Weight from "../../components/Weight/Weight";
import {list} from "../../components/Filling/Filling";
import Select from "../../components/Select/Select";
import StepperRow from "../StepperScreen/StepperRow";

const { TextArea } = Input;


const AddForm: React.FC = () => {
    const [form] = Form.useForm();
    const rate = Form.useWatch('rate', form);
    const subThb = Form.useWatch('sumThb', form);


    useEffect(() => {
        if (typeof subThb === 'undefined' || typeof rate === 'undefined') return;

        form?.setFieldValue('sumRub', Math.round(subThb * rate));
    }, [form, rate, subThb]);


    useEffect(() => {
        axios(`${process.env.REACT_APP_API_URL}/rates`).then((res) => {
            if (res?.data?.result) {
                form.setFieldValue('rate', res.data.result);
            }
        });
    }, [form]);

    const onFinish: FormProps['onFinish'] = (values) => {
        axios.post(`${process.env.REACT_APP_API_URL}/order`, values);
    };

    return (
        <>
            <Form
                form={form}
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 22 }}
                onFinish={onFinish}
                initialValues={{
                    rate: 1,
                }}
                layout="vertical"
            >
                <StepperRow h1="Детали торта">
                    <Form.Item
                        label="Вес торта"
                        required
                        rules={[{required: true}]}
                        shouldUpdate={(prevValues, curValues) => prevValues.weight !== curValues.weight}
                    >
                        {(form) => (
                            <Weight
                                value={form.getFieldValue('weight') || 0}
                                onChange={(v) => {
                                    form.setFieldValue('weight', v);
                                }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Начинка"
                        shouldUpdate
                    >
                        {(form) => (
                            <Select
                                value={form.getFieldValue('filling') || null}
                                onChange={(value) => form.setFieldValue('filling', value)}
                                placeholder="Выберите начинку"
                                options={list}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Комментарий" name="cakeComment">
                        <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item name="sumThb" label="Сумма в батах">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="rate" label="Курс конвертации в рубли">
                        <InputNumber step={0.01} />
                    </Form.Item>
                    <Form.Item name="sumRub" label="Сумма в рублях">
                        <InputNumber />
                    </Form.Item>
                </StepperRow>
                <StepperRow h1="Доставка">
                    <Form.Item label="Дата" name="date">
                        <DatePicker locale={locale} />
                    </Form.Item>
                    <Form.Item label="Получение" name="deliveryType">
                        <Radio.Group>
                            <Radio value={1}>Доставка</Radio>
                            <Radio value={0}>Самовывоз</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Комментарий" name="deliveryComment">
                        <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item label="Доставка сюрприз" valuePropName="isSurprise">
                        <Switch />
                    </Form.Item>
                    <Form.Item label="WhatsApp номер" name="wa">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Тайский номер" name="phone">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Заказ из" name="orderFrom">
                        <Input />
                    </Form.Item>
                </StepperRow>
                <Form.Item label="Заметка по заказу (не видна заказчику)" name="note">
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Отправить
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default AddForm;