import React, {FC, PropsWithChildren, ReactNode} from "react";
import {CheckCircleFilled, CheckCircleOutlined} from "@ant-design/icons";
import classNames from "classnames";

import styles from "./StepperRow.module.css";

const StepperRow: FC<{h1: ReactNode, h2?: ReactNode, showProgress?: boolean, filled?: boolean} & PropsWithChildren> = ({filled, children, h1, h2, showProgress= false}) => (
    <div className={styles.row}>
        <div className={styles.rowProgress}>
            <div className={styles.rowProgressIcon}>
                {React.createElement(filled ? CheckCircleFilled : CheckCircleOutlined, {
                    style: { fontSize: '30px', color: '#f37280', width: '30px', height: '30px' }
                })}
            </div>
            {showProgress && <div className={classNames(styles.rowProgressLine, {
                [styles.rowProgressLineFilled]: filled
            })}></div>}
        </div>
        <div className={styles.rowContent}>
            {h2 && <div className={styles.rowContentH2}>{h2}</div>}
            <div className={styles.rowContentH1}>{h1}</div>
            <div className={styles.rowContentChild}>{children}</div>
        </div>
    </div>
);

export default StepperRow;