import React, {FC, memo, useEffect, useRef, useState} from "react";
import {MessageInstance} from "antd/es/message/interface";
import copy from 'copy-to-clipboard';
import {captureException} from "@sentry/react";

import whatsapp from './SendMe/whatsapp.png';
import telegram from './SendMe/telegram.png';
import Gap from "../../components/Gap/Gap";

import styles from "./SendMe.module.css";
import {reachGoal} from "../../helper";
import {MainButton, useHapticFeedback, WebAppProvider} from "@vkruglikov/react-telegram-web-app";
import {useInView} from "react-intersection-observer";
import {ReactComponent as CopySvg} from './SendMe/copy.svg';

interface SendMeProps {
    text: string;
    twaMode: boolean;
    messageApi: MessageInstance;
    onVisible: () => void;
}

const SendMe: FC<SendMeProps> = memo(({twaMode, text, messageApi, onVisible}) => {
    const [, setHasCopied] = useState(false);
    const textDivRef = useRef<HTMLDivElement | null>(null);
    const isIntersecting = useRef(false);
    const [copyError, setCopyError] = useState(false);
    const [, notification] = useHapticFeedback()
    const { ref, inView } = useInView({
        triggerOnce: true
    })
    const [copiedText, setCopiedText] = useState('');


    useEffect(() => {
        const observer = new IntersectionObserver((items, observerInstance) => {
            isIntersecting.current = items[0].isIntersecting;
            if (isIntersecting.current) {
                onVisible();
                observerInstance.disconnect();
            }
        }, {
            root: null,
            rootMargin: "-150px",
        });
        observer.observe(textDivRef.current as Element);
    }, [onVisible]);

    const selectText = () => {
        if (window.getSelection && textDivRef.current) {
            const range = document.createRange();
            range.selectNode(textDivRef.current);
            window.getSelection()?.removeAllRanges();
            window.getSelection()?.addRange(range);
        }
    }

    const copyOrderText = () => {
        try {
            if (copy(text)) {
                if (!twaMode) {
                    messageApi.success('Сообщение для заказа скопировано, отправьте его нам!');
                }
            } else {
                selectText();
                if (twaMode) {
                    throw new Error('asd');
                }
            }
            if (twaMode) {
                setCopiedText(text);
                notification('success');
            }
            setCopyError(false);
        } catch (e) {
            setCopyError(true);
            captureException(e);
            if (twaMode) {
                notification('error');
            } else {
                messageApi.error('Не удалось скопировать сообщение, пожалуйста скопируйте его самостоятельно или сделайте скриншот!', 10);
            }
        } finally {
            reachGoal('copyMessage');
            setHasCopied(true);
        }
    };

    const isShowAnyBtn = twaMode && inView;
    const isShowDialogBtn = text === copiedText || copyError;

    const showButtonCopy = isShowAnyBtn && isShowDialogBtn;

    useEffect(() => {
        if (!showButtonCopy) return;

        if (copyError) {
            messageApi.error(
                'Не удалось скопировать сообщение, пожалуйста скопируйте его самостоятельно или сделайте скриншот!',
                0
            );
        } else {
            messageApi.success(
                `Сообщение для заказа скопировано!${'\r\n'}Перейдите в диалог и отправьте его нам!`,
                0
            );
        }

        return () => messageApi.destroy();
    }, [showButtonCopy, copyError, messageApi])

    return (
        <>
            <Gap />
            <div
                className={styles.copy}
            >
                <div
                    onClick={copyOrderText}
                    className={styles.copyMessage}
                    ref={(element) => {
                        textDivRef.current = element;
                        ref(element);
                    }}
                >
                    <CopySvg fill="#8c8c8c" className={styles.copyIcon} width={15} height={15} />
                    {text.split('\r\n').map((text, index) => <React.Fragment key={index}>{text}<br/></React.Fragment>)}
                </div>
            </div>
            {isShowAnyBtn && (
                <WebAppProvider options={{smoothButtonsTransition: true}}>
                    {isShowDialogBtn ? (
                        <MainButton key={"asd"} onClick={() => {
                            // @ts-ignore
                            window.Telegram?.WebApp?.openTelegramLink?.('https://t.me/phuket_cakes');
                        }} text="ПЕРЕЙТИ В ДИАЛОГ"/>
                    ) : (
                        <MainButton key="gfa" onClick={copyOrderText} text="СКОПИРОВАТЬ СООБЩЕНИЕ" color="#f37280" textColor="#fff"/>
                    )}
                </WebAppProvider>
            )}
            {!twaMode && (
                <>
                    <Gap />
                    {/*{!hasCopied && (*/}
                    {/*    <button*/}
                    {/*        onClick={copyOrderText}*/}
                    {/*        className={classNames(styles.orderButton, styles.orderButtonCopy)}*/}
                    {/*    >*/}
                    {/*        Скопировать сообщение для заказа*/}
                    {/*    </button>*/}
                    {/*)}*/}
                    {true && (
                        <>
                            {/*<div className={styles.copyText}>*/}
                            {/*    Сообщение скопировано в буфер обмена, перейдите в любой мессенджер и отправьте его нам!*/}
                            {/*</div>*/}
                            {/*<Gap />*/}
                            {/*<Gap />*/}
                            <a target="_blank" rel="noreferrer" onClick={() => reachGoal('goTg')} className={styles.orderButton} href={`tg://resolve?domain=phuket_cakes&text=${encodeURI(text)}`}>
                                <div
                                    style={{
                                        backgroundImage: `url(${telegram})`
                                    }}
                                    className={styles.orderButtonIcon}
                                />{' '}<div>Написать в Telegram</div>
                            </a>
                            <Gap />
                            <a target="_blank" rel="noreferrer" onClick={() => reachGoal('goWa')} className={styles.orderButton} href={`https://api.whatsapp.com/send?phone=+66627200744&text=${encodeURI(text)}`}>
                                <div
                                    style={{
                                        backgroundImage: `url(${whatsapp})`
                                    }}
                                    className={styles.orderButtonIcon}
                                />{' '}<div>Отправить заказ в WhatsApp</div>
                            </a>
                            <Gap />
                            {/*<a target="_blank" rel="noreferrer" onClick={() => reachGoal('goInst')} className={styles.orderButton} href="https://www.instagram.com/phuket_cakes/">*/}
                            {/*    <div*/}
                            {/*        style={{*/}
                            {/*            backgroundImage: `url(${instagram})`*/}
                            {/*        }}*/}
                            {/*        className={styles.orderButtonIcon}*/}
                            {/*    />{' '}<div>Написать в Instagram</div>*/}
                            {/*</a>*/}
                        </>
                    )}
                </>
            )}
        </>
    );
});

export default SendMe;