import Lightbox from "yet-another-react-lightbox";
import {Radio} from "antd";
import {FC, memo, useState} from "react";
import {Input} from 'antd';

import styles from './Delivery.module.css';
import Select from "../../components/Select/Select";

const slides = [
    {
        src: require(`./Delivery/map.png`),
        width: 720,
        height: 1280,
    }
];

const timeOptions = new Array(12).fill(null).reduce(((start) => (memo) => {
    memo.push({
        value: start,
        label: `${start}:00 - ${++start}:00`,
    });
    return memo;
})(10), []);

interface DeliveryProps {
    time: number | null;
    onChangeTime: (time: number) => void;
    value: string | null;
    comment: string;
    onChange: (value: string) => void;
    onChangeComment: (value: string) => void;
}

const Delivery: FC<DeliveryProps> = memo(({time, onChangeTime, value, onChange, comment, onChangeComment}) => {
    const [index, setIndex] = useState(-1);

    return (
        <>
            <div onClick={() => setIndex(0)} className={styles.cover}>
                <div className={styles.coverIcon}></div>
                <div className={styles.coverName}>Карта доставки</div>
            </div>
            <div className={styles.wrapper}>
                <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
                    <Radio value="pickup">Самовывоз</Radio>
                    <Radio value="delivery">Нужна доставка</Radio>
                </Radio.Group>
                <div className={styles.comment}>
                    <Select
                        disabled={!value}
                        value={time}
                        onChange={onChangeTime}
                        placeholder="Интервал времени"
                        style={{width: 200}}
                        options={timeOptions}
                    />
                </div>
                <div className={styles.comment}>
                    <Input.TextArea maxLength={50} showCount value={comment} onChange={(e) => onChangeComment(e.target.value)} placeholder="Комментарий"></Input.TextArea>
                </div>
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
            />
        </>
    );
});

export default Delivery;