import Screen from "../../components/Screen/Screen";
import Content from "../../components/Content/Content";
import AddForm from "./AddForm";

const OrderViewScreen = () => {
    return (
        <div>
            <Screen>
                <Content>
                    <AddForm />
                </Content>
            </Screen>
        </div>
    );
}

export default OrderViewScreen;