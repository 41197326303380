import React, {FC, useLayoutEffect} from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import 'antd/es/style/reset.css';
import "yet-another-react-lightbox/styles.css";
import './styles/variables.css';


import StepperScreen from "./screens/StepperScreen/StepperScreen";
import OrderViewScreen from "./screens/OrderViewScreen/OrderViewScreen";
import MainScreen from "./screens/MainScreen/MainScreen";


const router = createBrowserRouter([
    {
        path: "/order",
        element: <StepperScreen />,
    },
    {
        path: "/twa/order",
        element: <StepperScreen twaMode />,
    },
    {
        path: "/t/order/new",
        element: <OrderViewScreen />,
    },
    {
        path: "*",
        element: <MainScreen />
    }
]);

// FIXME ГРузить telegram-web-app.js  только для twa а не для всех
const WebApp: FC = () => {
    useLayoutEffect(() => {
        window.__HIDRATE_SSR_DEVTOOLS3 = document.getElementById("root")?.outerHTML || '';
    })

    return (
        <RouterProvider router={router} />
    );
}

export default WebApp;