import React, {FC} from 'react';
import {Col, Row, Statistic} from "antd";
import Gap from "../../components/Gap/Gap";

import styles from "./Price.module.css";

export const getCakePrice = (filling: string | null, weight: number | null) => {
    if (!filling || !weight) return 0;

    if (filling === '7' && weight === 2) {
        return 2800;
    }
    if (filling === '7') {
        return Math.floor(weight * (2100 / 1.5));
    }
    if (weight === 0.4) {
        return 1000;
    }
    if (['9', '10'].includes(filling)) {
        return Math.floor(weight * 1500);
    }

    return Math.floor(weight * 1400);
};

interface PriceProps {
    cost: number;
    hasDelivery: boolean;
    list: {label: string; cost: number}[]
}

const Price: FC<PriceProps> = ({hasDelivery, cost, list}) => {
    return (
        <>
            <Row gutter={16}>
                <Col span={16}>
                    {list.map((item, index) => (
                        <div key={index} className={styles.basketRow}>{item.label} {item.cost} бат</div>
                    ))}
                    <div className={styles.basketRow}>Стоимость торта без учета дизайна и декораций</div>
                    {hasDelivery && (
                        <div className={styles.basketRow}>
                            Стоимость доставки может быть скорректирована после уточнения адреса
                        </div>
                    )}
                </Col>
                <Col span={8}>
                    <Statistic suffix={!!cost && 'бат'} title="Стоимость торта" value={cost} />
                </Col>
            </Row>
            <Gap />
        </>
    );
};

export default Price;