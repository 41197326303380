import {Slider} from "antd";
import {TeamOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import React, {FC, memo} from "react";

import styles from './Weight.module.css';
import classNames from "classnames";

interface WeightProps {
    value: number;
    onChange: (value: number) => void;
}

const getWeightText = (value: number) => {
    if (value === 0.4)  {
        return '400 гр'
    }
    return `${value} кг`;
}

const getCountPeople = (value: number) => {
    const min = Math.floor(value / 0.200);
    const max = Math.floor(value / 0.150);

    return min !== max ? `${min}-${max}` : min;
};

const CakeTypeButton: FC<{
    text: string;
    onClick: () => void;
    selected: boolean;
}> = ({onClick, text, selected}) => (
    <button type="button" onClick={onClick} className={classNames(styles.buttonType, {
        [styles.buttonTypeSelected]: selected
    })}>{text}</button>
);

const weights = [
    0,
    0.4,
    1,
    1.5,
    2,
    2.5,
    3,
    3.5,
    4,
    4.5,
    5,
];

const MARKERS = weights.reduce((memo, item) => {
    if (item) {
        memo[item] = ' ';
    }
    return memo;
}, {});

const Weight: FC<WeightProps> = memo(({value, onChange}) => (
    <>
        <div>
            <CakeTypeButton text="бенто" onClick={() => onChange(0.4)} selected={value === 0.4} />
            <CakeTypeButton text="простой декор" onClick={() => onChange(1)} selected={value > 0} />
            <CakeTypeButton text="сложный декор" onClick={() => onChange(1.5)} selected={value >= 1.5} />
            <CakeTypeButton text="двухъярусный" onClick={() => onChange(4)} selected={value >= 4} />
        </div>
        <div className={styles.common}>
            <div className={styles.commonWeight}>
                {getWeightText(value)}
            </div>
            {value > 0 && (
                <div className={styles.commonPeople}>
                    <TeamOutlined style={{
                        width: '30px',
                        height: '30px',
                    }} />{' '}
                    {getCountPeople(value)}
                </div>
            )}
        </div>
        <div className={styles.buttonPlusWrapper}>
            <button type="button" onClick={() => !isNaN(weights[weights.indexOf(value) - 1]) && onChange(weights[weights.indexOf(value) - 1])} className={styles.buttonPlus}>
                <MinusCircleOutlined style={{
                    width: '26px',
                    height: '26px',
                }} />
            </button>
            <Slider
                className={styles.slider}
                marks={MARKERS}
                tooltip={{ open: false }}
                min={0}
                max={5}
                step={null}
                onChange={onChange}
                value={value}
            />
            <button type="button" onClick={() => weights[weights.indexOf(value) + 1] && onChange(weights[weights.indexOf(value) + 1])} className={styles.buttonPlus}>
                <PlusCircleOutlined
                    style={{
                        width: '26px',
                        height: '26px',
                    }}
                />
            </button>
        </div>
        <div>
            Рекомендуемая порция на человека 150-200 грамм
        </div>
    </>
));

export default Weight;