import {Link} from "react-router-dom";
import {InstagramOutlined, WhatsAppOutlined, SendOutlined} from "@ant-design/icons";

import Content from "../../components/Content/Content";
import Screen from "../../components/Screen/Screen";
import Gap from "../../components/Gap/Gap";

import styles from './MainScreen.module.css';

const MainScreen = () => {
    return (
        <Screen>
            <Content>
                <div className={styles.wrapper}>
                    <h1>Торты на Пхукете</h1>
                    <Gap />
                    <div className={styles.icons}>
                        <InstagramOutlined />
                        <WhatsAppOutlined />
                        <SendOutlined />
                    </div>
                    <Gap />
                    <Link className={styles.order} to="/order/">
                        Заказать
                    </Link>
                </div>
            </Content>
        </Screen>
    );
};

export default MainScreen;