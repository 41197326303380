import {SelectProps as SelectPropsAntd} from 'antd'
import {FC} from "react";

import styles from './Select.module.css';
import classNames from "classnames";


const Select: FC<SelectPropsAntd> = ({options, onChange, ...rest}) => (
    <select
        disabled={rest.disabled}
        value={JSON.stringify(rest.value)}
        style={rest.style}
        onChange={(e) => onChange?.(JSON.parse(e.target.value), {
            label: 's'
        })}
        className={classNames(styles.select, {
            [styles.selectEmpty]: typeof rest.value === 'undefined'
        })}
    >
        <option disabled value={JSON.stringify(null)} >{rest.placeholder}</option>
        {options?.map((item, index) => (
            <option key={index} value={JSON.stringify(item.value)}>{item.label}</option>
        ))}
    </select>
);

export default Select;