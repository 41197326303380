import * as React from 'react';
import { hydrateRoot, createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import dayjs from "dayjs";

import 'intersection-observer-polyfill/index.global';
import 'dayjs/locale/ru';

import WebApp from "./WebApp";

dayjs.locale('ru');

const IS_REACT_SNAP =
    navigator.userAgent === "ReactSnap";

if (!IS_REACT_SNAP) {
    Sentry.init({
        dsn: "https://03eb889f6e6c4b45a9033fdfb02c700b@o487063.ingest.sentry.io/4504721992187904",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

declare global {
    interface Window { __HIDRATE_SSR_DEVTOOLS: string; __HIDRATE_SSR_DEVTOOLS2: string; __HIDRATE_SSR_DEVTOOLS3: string}
}

const rootElement = document.getElementById("root") as Element;
if (rootElement.hasChildNodes()) {
    console.log('SSR: hydrateRoot');
    hydrateRoot(rootElement, <WebApp />);
} else {
    const root = createRoot(rootElement);
    root.render(<WebApp />);
}
window.__HIDRATE_SSR_DEVTOOLS = rootElement?.outerHTML || '';

requestAnimationFrame(() => {
    window.__HIDRATE_SSR_DEVTOOLS2 = rootElement?.outerHTML || '';
});