import dayjs from 'dayjs';
import React, {FC, useEffect, useMemo, useState} from "react";
import {useCallback} from "rely-use-callback";

import {Modal, message} from 'antd';

import Screen from "../../components/Screen/Screen";
import Content from "../../components/Content/Content";

import Weight from "../../components/Weight/Weight";
import Filling, {list} from "../../components/Filling/Filling";
import Delivery from "./Delivery";
import Price, {getCakePrice} from "./Price";
import StepperRow from "./StepperRow";
import DateComponent from "./Date";
import SendMe from "./SendMe";
import {reachGoal} from "../../helper";
import Gap from "../../components/Gap/Gap";
import styles from './StepperScreen.module.css'


const urlParams = new URLSearchParams(window.location.search);

const StepperScreen: FC<{
    twaMode?: boolean
}> = ({twaMode = false}) => {
    const [modal, contextHolder] = Modal.useModal();
    const [messageApi, contextMessageHolder] = message.useMessage();

    const [weight, _setWeight] = useState(0);
    const [date, setDate] = useState<dayjs.Dayjs>();
    const [time, setTime] = useState<number | null>(null);
    const [delivery, setDelivery] = useState<string | null>(null);
    const [deliveryComment, setDeliveryComment] = useState<string>('');
    const [filling, _setFilling] = useState<string | null>(null);
    const [sendMeVisible, setSendMeVisible] = useState(false);

    useEffect(() => {
        if (weight) {
            reachGoal('weight', true);
        }
    }, [weight]);

    useEffect(() => {
        if (filling) {
            reachGoal('filling', true);
        }
    }, [filling]);

    useEffect(() => {
        if (date) {
            reachGoal('date', true);
        }
    }, [date]);

    const setWeight = useCallback((value: number) => {
        if (['1'].includes(filling as string) && value < 1) {
            reachGoal('lessWeightSuggestion');
            modal.confirm({
                okText: 'Уменьшить',
                onOk: () => {
                    _setWeight(value);
                    _setFilling(null);
                },
                cancelText: `Отмена`,
                title: 'Для вашей начинки минимальный вес 1 кг',
                content: 'Уменьшить вес? Нужно будет заново выбрать начинку'
            });
        } else  if (['6', '7', '11'].includes(filling as string) && value < 1.5) {
            reachGoal('lessWeightSuggestion');
            modal.confirm({
                okText: 'Уменьшить',
                onOk: () => {
                    _setWeight(value);
                    _setFilling(null);
                },
                cancelText: `Отмена`,
                title: 'Для вашей начинки минимальный вес 1.5 кг',
                content: 'Уменьшить вес? Нужно будет заново выбрать начинку'
            });
        } else  if (['9', '10'].includes(filling as string) && value < 1.5) {
            reachGoal('lessWeightSuggestion');
            modal.confirm({
                okText: 'Уменьшить',
                onOk: () => {
                    _setWeight(value);
                    _setFilling(null);
                },
                cancelText: `Отмена`,
                title: 'Для вашей начинки минимальный вес 2 кг',
                content: 'Уменьшить вес? Нужно будет заново выбрать начинку'
            });
        } else {
            _setWeight(value);
        }
    }, [modal, filling]);

    const setFilling = useCallback((value: string) => {
        if (['1'].includes(value) && weight < 1) {
            reachGoal('moreWeightSuggestion');
            modal.confirm({
                okText: 'Увеличить',
                onOk: () => {
                    _setFilling(value);
                    _setWeight(1);
                },
                cancelText: `Отмена`,
                title: 'Данная начинка идет от 1 кг',
                content: 'Увеличить вес торта до 1 кг?'
            });
        } else if (['6', '7', '11'].includes(value) && weight < 1.5) {
            reachGoal('moreWeightSuggestion');
            modal.confirm({
                okText: 'Увеличить',
                onOk: () => {
                    _setFilling(value);
                    _setWeight(1.5);
                },
                cancelText: `Отмена`,
                title: 'Данная начинка идет от 1.5 кг',
                content: 'Увеличить вес торта до 1.5 кг?'
            });
        } else if (['9', '10'].includes(value) && weight < 2) {
            reachGoal('moreWeightSuggestion');
            modal.confirm({
                okText: 'Увеличить',
                onOk: () => {
                    _setFilling(value);
                    _setWeight(2);
                },
                cancelText: `Отмена`,
                title: 'Данная начинка идет от 2 кг',
                content: 'Увеличить вес торта до 2 кг?'
            });
        } else {
            _setFilling(value);
        }
    }, [modal, weight]);

    const priceObject = useMemo(() => {
        const list = [{
            label: 'Начинка',
            cost: getCakePrice(filling, weight)
        }];

        if (delivery === 'delivery') {
            list.push({
                label: 'Доставка от',
                cost: 250
            });
        }

        return {
            cost: list.reduce((sum, item) => sum + item.cost, 0),
            list: list.filter((item) => item.cost),
        };
    }, [delivery, weight, filling]);

    const textForOrder = 'Здравствуйте, хочу заказать тортик!' +
        (date ? `\r\nДата: ${date?.format('D MMMM')}` : '') +
        (weight ? `\r\nВес торта: ${weight === 0.4 ? 'бенто (400-450 грамм)' : `${weight} кг`}` : '') +
        (filling ? `\r\n Начинка: ${list.find(item => item.value === filling)?.label}` : '') +
        (delivery ? `\r\n${delivery === 'delivery' ? 'Нужна доставка' : 'Самовывоз'}` : '') +
        (time ? `\r\nВременной интервал ${time}:00 - ${time+1}:00` : '') +
        (deliveryComment ? `\r\n${deliveryComment}` : '') +
        (priceObject.cost ? (
            '\r\n\r\nКалькулятор насчитал'
            + priceObject.list.map((item) => `\r\n${item.label} ${item.cost} бат`).join('')
        ) : '') +
        '';

    const onVisible = useCallback(() => setSendMeVisible(true), []);

    return (
        <Screen bottomOffset={!twaMode}>
            {contextHolder}{contextMessageHolder}
            {urlParams.get('fbclid') === null && (
                <Content maxWidth={458}>
                    <section className={styles.welcome}>
                        <h1>Торты на Пхукете на заказ</h1>
                        <p>
                            Посмотреть примеры тортов, отзывы или задать любой интересующий вас вопрос можно в нашем инстаграме <a href="https://www.instagram.com/phuket_cakes/">@phuket_cakes</a>{' '}
                            или написав в telegram <a href="https://t.me/phuket_cakes">@phuket_cakes</a>.<br/>
                            Доставка только по острову Пхукет
                        </p>
                    </section>
                </Content>
            )}
            <Gap />
            <Content maxWidth={458}>
                <StepperRow
                    h2="Шаг 1"
                    h1="Выбрать вес торта"
                    showProgress
                    filled={!!weight}
                >
                    <Weight value={weight} onChange={setWeight} />
                </StepperRow>
                <StepperRow
                    h2="Шаг 2"
                    h1="Выбрать начинку"
                    showProgress
                    filled={!!filling}
                >
                    <Filling value={filling} onChange={setFilling}/>
                </StepperRow>
                <StepperRow
                    h2="Шаг 3"
                    h1="Выбрать дату"
                    showProgress
                    filled={!!date}
                >
                    <DateComponent messageApi={messageApi} value={date} onChange={setDate} />
                </StepperRow>
                <StepperRow
                    h2="Шаг 4"
                    h1="Доставка или самовывоз"
                    showProgress
                    filled={!!delivery}
                >
                    <Delivery
                        time={time}
                        onChangeTime={setTime}
                        value={delivery}
                        onChange={setDelivery}
                        comment={deliveryComment}
                        onChangeComment={setDeliveryComment}
                    />
                </StepperRow>
            </Content>
            <Gap />
            <Content maxWidth={458}>
                <Price
                    hasDelivery={delivery === 'delivery'}
                    cost={priceObject.cost}
                    list={priceObject.list}
                />
                <StepperRow
                    h2="Шаг 5"
                    h1="Отправить заказ в мессенджер"
                    showProgress
                    filled={sendMeVisible && !!delivery && !!weight && !!filling && !!date}
                >
                    <SendMe
                        twaMode={twaMode}
                        onVisible={onVisible}
                        text={textForOrder}
                        messageApi={messageApi}
                    />
                </StepperRow>
            </Content>
        </Screen>
    )
};

export default StepperScreen;