import React, {FC, PropsWithChildren} from 'react';

import styles from './Content.module.scss';

const Content: FC<PropsWithChildren & {maxWidth?: number}> = ({children, maxWidth = 458}) => (
    <div className={styles.content} style={{
            maxWidth
        }}>
            {children}
    </div>
);

export default Content;