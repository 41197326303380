import React, {FC, PropsWithChildren} from 'react';

import styles from './Screen.module.scss';
import classNames from "classnames";

interface IScreenBaseProps extends PropsWithChildren {
    flex?: boolean;
    flexCenter?: boolean;
    height?: number;
    bottomOffset?: boolean;
}

const Screen: FC<IScreenBaseProps> = ({bottomOffset = true,children, height, flex, flexCenter}) => (
    <div
        className={classNames(styles.screen, {
            [styles['screen_flexible']]: flex,
            [styles['screen_flexibleCenter']]: flexCenter,
            [styles['screen_bottomOffset']]: bottomOffset,
        })}
        style={{
            height
        }}
    >
        {children}
    </div>
);

export default Screen;