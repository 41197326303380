import {FC, memo, useState} from "react";
import {Radio, Space} from 'antd';
import Lightbox from "yet-another-react-lightbox";

import styles from './Filling.module.css';
import {RelyCallback} from "rely-use-callback";

export const list:{
    label: string,
    value: string,
    noPic?: boolean
}[] = [
    {
        label: '🍌 Банан-карамель (от 1кг)',
        value: '1'
    }, {
        label: '🥜 Сникерс',
        value: '2'
    },  {
        label: '🥥 Кокос-манго-маракуйя',
        value: '3'
    }, {
        label: '🍪 Шоколад-орео',
        value: '4'
    }, {
        label: '🍓 Красный бархат с клубникой',
        value: '5'
    }, {
        label: '🫐 Ягодный',
        value: '8',
    }, {
        label: '🍊 Морковный с апельсином и грецкими орехами (от 1.5 кг)',
        value: '6'
    }, {
        label: '🎂 Наполеон со сливочно-заварным кремом (от 1.5 кг)',
        value: '7',
    },
    {
        label: '🍹 Клубничный мохито (от 2 кг)',
        value: '9'
    },
    {
        label: '🥭 Манго-трюфель (от 2 кг)',
        value: '10'
    },
    {
        label: '🍯 Медовик (от 1.5 кг)',
        value: '11',
    },
];

type ExcludesFalse = <T>(x: T | null) => x is T;

const slides = list.map((item) => !item.noPic ? ({
    src: require(`./${item.value}.jpg`),
    key: 3221,
    width: 720,
    height: 1280,
}) : null).filter(Boolean as any as ExcludesFalse);


interface FillingProps {
    value: string | null;
    onChange: RelyCallback<(value: string) => void>;
}

const Filling: FC<FillingProps> = memo(({value, onChange}) => {
    const [index, setIndex] = useState(-1);

    return (
        <>
            <div onClick={() => setIndex(0)} className={styles.cover}>
                <div className={styles.coverIcon}></div>
                <div className={styles.coverName}>Начинки в разрезе</div>
            </div>
            <div className={styles.wrapper}>
                <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
                    <Space direction="vertical">
                        {list.map((item) => (
                            <Radio key={item.value} value={item.value}>{item.label}</Radio>
                        ))}
                    </Space>
                </Radio.Group>
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
            />
        </>
    );
});

export default Filling;